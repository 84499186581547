import React from 'react'
import loadable from '@loadable/component'

const ComponentList = {
  carousel: loadable(() => import(`./Carousel`)),
  centeredText: loadable(() => import(`./CenteredText`)),
  details: loadable(() => import(`./Details`)),
  productDetails: loadable(() => import(`./ProductDetails`)),
  productList: loadable(() => import(`./ProductList`)),
  knowHowList: loadable(() => import(`./KnowHowList`)),
}

export default function TemplateElement(props) {
  const Component = ComponentList[props.content.element]

  if (Component === undefined) return <></>
  return (
    <Component
      products={props.products}
      productSpecs={props.productSpecs}
      defaultSpecs={props.defaultSpecs}
      relatedAssets={props.relatedAssets}
      relatedOptions={props.relatedOptions}
      relatedDesigns={props.relatedDesigns}
      productCarousel={props.productCarousel}
      bild={props.bild}
      externalLinks={props.externalLinks}
    />
  )
}
